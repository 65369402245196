// import React, { useState } from 'react';
// import image from '../../../assets/photos/lastImage.png';
// import css from './SectionWhyNew.module.css';
// import { NamedLink } from '../../../components';
// import { Carousel } from 'react-responsive-carousel';
// import leftArrow from '../../../assets/photos/arrow_left.png';
// import rightArrow from '../../../assets/photos/arrow_right.png';
// const SectionImage = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handleChange = index => {
//     setCurrentIndex(index);
//   };
//   console.log('currentIndex', currentIndex);
//   return (
//     <div className={css.imageContainer}>
//       <Carousel
//         autoPlay={true}
//         showStatus={false}
//         showThumbs={false}
//         showArrows={true}
//         showIndicators={true}
//         infiniteLoop={true}
//         swipeable={true}
//         transitionTime={2000}
//         interval={3000}
//         onChange={handleChange}
//         // renderArrowPrev={}
//         // renderArrowNext={}
//       >
//         <img className={css.image} src={image} alt="lastImage" />
//         <img className={css.image} src={image} alt="lastImage" />
//       </Carousel>
//       {/* <div className={css.buttonContainer}>
//         <button className={css.leftArrow}>
//           <img src={leftArrow} alt="leftArrow" className={css.arrow} />
//         </button>
//         <button className={css.rightArrow}>
//           <img src={rightArrow} alt="rightArrow" className={css.arrow} />
//         </button>
//       </div> */}

//       <div className={css.imageText}>
//         <h2 className={css.imageHeading}>Made for creators by Creators</h2>
//         <p className={css.text}>
//           Straight out of the creative agency scene, our founder is all about that creator life.
//           Here at ukreate, we're creators first, always. We get it, and we're here to make sure you
//           do too.
//         </p>
//         <p className={css.text}>
//           Think of ukreate as your go-to spot for making magic happen. We're all about clear, smooth
//           connections between creatives and businesses. No tuss, no mess-just straight-up good vibes
//           and great opportunities. Let's create something epic together.
//         </p>
//         <br />
//         <NamedLink name="SearchPage" className={css.heroButton}>
//           Let’s Start
//         </NamedLink>
//       </div>
//     </div>
//   );
// };

// export default SectionImage;
import React, { useState } from 'react';
import image from '../../../assets/photos/lastImage.png';
import css from './SectionWhyNew.module.css';
import { NamedLink } from '../../../components';
import { Carousel } from 'react-responsive-carousel';
import leftArrow from '../../../assets/photos/arrow_left.png';
import rightArrow from '../../../assets/photos/arrow_right.png';
import Community_and_Experiences from '../../../assets/photos/Community_and_Experiences.jpg';
import Earn_more_and_grow_more from '../../../assets/photos/Earn_more_and_grow_more.jpg';
const SectionImage = ({ currentUser }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { attributes } = currentUser || {};
  const { profile } = attributes || {};
  const { publicData } = profile || {};
  const { userType } = publicData || {};
  const isBrand = userType === 'brand';
  const handleChange = index => {
    setCurrentIndex(index);
  };
  const text = [
    {
      heading: 'Made For Creators By Creators',
      image: image,
      text: (
        <p className={css.text}>
          Straight from the agency world, our founder embodies the creator spirit. At ukreate, we
          prioritize creators, ensuring you thrive alongside us. Consider ukreate your hub for
          seamless collaborations between creatives and businesses—no fuss, just great vibes and
          opportunities. Let's create something epic together.
        </p>
      ),
      buttonText: 'Let’s Start',
    },
    {
      heading: 'Community & Experiences',
      image: Community_and_Experiences,
      text: (
        <p className={css.text}>
          Join a community of both rising and established creators at ukreate. Whether you're
          turning content creation into a career or just leveling up, we connect you through global
          events, exclusive gatherings, and more. Ready to dive in?
        </p>
      ),
      buttonText: 'Let’s Start',
    },
    {
      heading: 'Earn More & Grow More',
      image: Earn_more_and_grow_more,
      text: (
        <p className={css.text}>
          We equip you with the tools to turn your online hustle into real earnings. Boost your
          client list and your bank account with us. Join us on our journey to become the largest
          creator platform—when we succeed, you do too. Bring your creative friends or favorite
          brands on board easily with our affiliate program. Let’s grow together!
        </p>
      ),
      buttonText: 'Let’s Start',
    },
  ];
  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.leftArrow}
      aria-label={label}
      disabled={!hasPrev}
    >
      <img src={leftArrow} alt="Previous" className={css.arrow} />
    </button>
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.rightArrow}
      aria-label={label}
      disabled={!hasNext}
    >
      <img src={rightArrow} alt="Next" className={css.arrow} />
    </button>
  );

  return (
    <div className={css.imageContainer}>
      <Carousel
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
        showArrows={true}
        showIndicators={true}
        infiniteLoop={true}
        swipeable={true}
        transitionTime={2000}
        interval={5000}
        onChange={handleChange}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
      >
        {text.map((item, index) => (
          <div className={css.images}>
            <img className={css.image} src={item.image} alt="lastImage" />
            <div className={css.imageTextDesktop}>
              <h2 className={css.imageHeading}>{item.heading}</h2>
              {item.text}
              <br />
              <NamedLink
                name={currentUser ? (isBrand ? 'SearchPage' : 'NewListingPage') : 'SignupPage'}
                to={{ userType: 'creator' }}
                className={css.heroButton}
              >
                {text[currentIndex] ? text[currentIndex].buttonText : 'Let’s Start'}
              </NamedLink>
            </div>
          </div>
        ))}

        {/* <img className={css.image} src={Community_and_Experiences} alt="lastImage" />
        <img className={css.image} src={Earn_more_and_grow_more} alt="lastImage" /> */}
      </Carousel>

      <div className={css.imageText}>
        <h2 className={css.imageHeading}>
          {text[currentIndex] ? text[currentIndex].heading : 'Made for creators by Creators'}
        </h2>
        {text[currentIndex] ? text[currentIndex].text : null}
        <br />
        <NamedLink
          name={currentUser ? (isBrand ? 'SearchPage' : 'NewListingPage') : 'SignupPage'}
          to={{ userType: 'creator' }}
          className={css.heroButton}
        >
          {text[currentIndex] ? text[currentIndex].buttonText : 'Let’s Start'}
        </NamedLink>
      </div>
      {/* <div className={css.imageText}>
        <h2 className={css.imageHeading}>Made for creators by Creators</h2>
        <p className={css.text}>
          Straight out of the creative agency scene, our founder is all about that creator life.
          Here at ukreate, we're creators first, always. We get it, and we're here to make sure you
          do too.
        </p>
        <p className={css.text}>
          Think of ukreate as your go-to spot for making magic happen. We're all about clear, smooth
          connections between creatives and businesses. No tuss, no mess-just straight-up good vibes
          and great opportunities. Let's create something epic together.
        </p>
        <br />
        <NamedLink name="SearchPage" className={css.heroButton}>
          Let’s Start
        </NamedLink>
      </div> */}
    </div>
  );
};

export default SectionImage;
