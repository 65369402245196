import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import SectionHero from './SectionHero/SectionHero';
import { Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './LandingPage.module.css';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionLast from './SectionLast/SectionLast';
import HowItWorksBrandAndAgency from './HowItWorksBrandAndAgency/HowItWorksBrandAndAgency';
import SectionWhy from './SectionWhy/SectionWhy';
import SectionCreators from './SectionCreators/SectionCreators';
import SectionHeroNew from './SectionHeroNew/SectionHeroNew';
import SectionHowItWorksNew from './SectionHowItWorksNew/SectionHowItWorksNew';
import SectionWhyNew from './SectionWhyNew/SectionWhyNew';
import FooterNew from './FooterNew/FooterNew';
const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error, currentUser } = props;

  return (
    <Page
    // title={title}
    >
      <TopbarContainer
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        mobileClassName={css.mobileTopbar}
        isLandingPage={true}
      />
      <div className={css.root}>
        <SectionHeroNew currentUser={currentUser} />
        <SectionHowItWorksNew currentUser={currentUser} />
        <SectionWhyNew currentUser={currentUser} />
        {/* <SectionHero />
        <HowItWorksBrandAndAgency />
        <SectionHowItWorks />
        <SectionWhy />
        <SectionCreators />
        <SectionLast /> */}
      </div>
      <FooterContainer />
    </Page>
  );
};
{
  /* // <PageBuilder
    //   pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
    //   inProgress={inProgress}
    //   error={error}
    //   fallbackPage={<FallbackPage error={error} />}
    // /> */
}
LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { currentUser } = state.user;

  return { pageAssetsData, inProgress, error, currentUser };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
