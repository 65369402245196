import React from 'react';
import css from './SectionHowItWorksNew.module.css';
import left from '../../../assets/photos/left.png';
import right from '../../../assets/photos/right.png';
import one from '../../../assets/photos/01.png';
import two from '../../../assets/photos/02.png';
import three from '../../../assets/photos/03.png';
import signup from '../../../assets/photos/signup.png';
import search from '../../../assets/photos/search.png';
import pay from '../../../assets/photos/pay.png';
import lineImage from '../../../assets/photos/lines.png';
const SectionHowItWorksNew = () => {
  return (
    <div className={css.root}>
      <h2 className={css.heading}>How it Works for Brands & Agencies</h2>
      <div className={css.sectionOne}>
        <div className={css.left}>
          <img src={left} alt="left" className={css.leftImage} />
        </div>
        <div className={css.center}>
          <img src={one} alt="one" className={css.oneImage} />
        </div>

        <div className={css.absolute}>
          <div className={css.centerMobile}>
            <img src={one} alt="one" className={css.oneImage} />
          </div>
          <img src={signup} alt="signup" className={css.signupImage} />

          <div className={css.right}>
            <div className={css.textContainer}>
              <h3 className={css.cardHeading}>Make Your Account</h3>
              <p>NO DEMO NEEDED</p>
              <p>
                Sign-up on the ukreate platform for a FREE membership – it takes less than 30
                seconds to join!
              </p>
            </div>
          </div>
        </div>
        <div className={css.redDot}>.</div>
        <div className={css.orangeDot}>.</div>
        <div className={css.redDotBig}>.</div>
        <div className={css.orangeDotRight}>.</div>
        <div className={css.redDotRight}>.</div>
        <div className={css.redSmallLeft}>.</div>
        <img src={lineImage} alt="how it works " className={css.lineOne} />
      </div>

      <div className={css.sectionTwo}>
        <div></div>
        <div className={css.center}>
          <img src={two} alt="two" className={css.oneImage} />
        </div>
        <div className={css.left}>
          <img src={right} alt="right" className={css.rightImage} />
        </div>
        <div className={css.absolute}>
          <div className={css.centerMobile}>
            <img src={two} alt="two" className={css.oneImage} />
          </div>
          <div className={css.rightDesktop}>
            <div className={css.textContainer}>
              <h3 className={css.cardHeading}>Find Your Perfect Creator</h3>
              <p>TARGETED SEARCH MADE SIMPLE</p>
              <p>
                Use advanced filters to locate creators by geolocation, niche, platform, or budget,
                ensuring you find the perfect fit for your brand’s needs.
              </p>
            </div>
          </div>

          <img src={search} alt="search" className={css.signupImage} />
          <div className={css.rightMobile}>
            <div className={css.textContainer}>
              <h3 className={css.cardHeading}>Find Your Perfect Creator</h3>
              <p>TARGETED SEARCH MADE SIMPLE</p>
              <p>
                {' '}
                Use advanced filters to locate creators by geolocation, niche, platform, or budget,
                ensuring you find the perfect fit for your brand’s needs.
              </p>
            </div>
          </div>
        </div>
        <div className={css.redDot}>.</div>
        <div className={css.orangeDot}>.</div>
        <div className={css.redDotBig}>.</div>
        <div className={css.orangeDotRight}>.</div>
        <div className={css.redDotRight}>.</div>
        <div className={css.redSmallLeft}>.</div>
        <img src={lineImage} alt="how it works " className={css.lineTwo} />
      </div>
      <div className={css.sectionThree}>
        <div className={css.leftLast}>
          <img src={left} alt="left" className={css.leftImage} />
        </div>
        <div className={css.center}>
          <img src={three} alt="three" className={css.oneImage} />
        </div>

        <div className={css.absolute}>
          <div className={css.centerMobile}>
            <img src={three} alt="three" className={css.oneImage} />
          </div>
          <img src={pay} alt="pay" className={css.signupImage} />
          <div className={css.right}>
            <div className={css.textContainer}>
              <h3 className={css.cardHeading}>Collaborate and Grow Your Brand</h3>
              <p>
                <b>START CREATING TODAY</b>
              </p>
              <p>
                Once connected, outline your project details, agree on payment, and watch your
                chosen creator bring your vision to life, helping to grow your brand’s social media
                presence.
              </p>
            </div>
          </div>
        </div>
        <div className={css.redDot}>.</div>
        <div className={css.orangeDot}>.</div>
        <div className={css.redDotBig}>.</div>
        <div className={css.orangeDotRight}>.</div>
        <div className={css.redDotRight}>.</div>
        <div className={css.redSmallLeft}>.</div>
        <img src={lineImage} alt="how it works " className={css.lineThree} />
      </div>
    </div>
  );
};

export default SectionHowItWorksNew;
